<template>

 
      <div class="mapVideo_content_box">
          <div id="mapVideo" class="mapvideo_show_box"></div>
      </div>

</template>

<script>
    let Hls = require('hls.js')
    
    export default {
        name: 'mapVideo',
        props:{
            videoData: String ,
            typeKey: String
        },
        data() {
             return {
                 player: null
             }
        },
        watch:{
            videoData(val){
                if(val){
                   
                    this.$nextTick(() => {
                        this.createVideo(this.flv)
                    })
                }
                
            }
        },
        mounted(){
            this.$nextTick(() => {
                
                this.createVideo(this.flv)
            })
         
        },
        computed:{
           flv(){
            console.log(JSON.parse(this.videoData))
              return this.videoData ? JSON.parse(this.videoData) ?.[this.typeKey] : ''
           }
        },
        beforeDestroy(){
          window.flvPlayer = null
          if(this.player) this.player.destroy()
        },
        methods:{
            delClose(){
                window.flvPlayer = null
                this.$emit('close')
                this.player.destroy()
            },
           
             //  创建视频播放
            createVideo(url){
                if (this.player) this.player.destroy();
                if(!this.flv) return
                this.typeKey == 'hls' ? this.createHls(url) : this.createFlv(url)    
            },
            // 加载hls视频流
            createHls(url){
                let videoDome = `<video autoplay controls muted width= '100%'  height="100%" id="videodomes" ref= 'videodomes' style= "object-fit: fill"></video>`   
                document.getElementById("mapVideo").innerHTML = videoDome;
                let videoElement = document.getElementById('videodomes');      

                let hls = new Hls();
                hls.loadSource(url);
                hls.attachMedia(videoElement);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        videoElement.play();
                });
                this.player = hls

            }, 
            createFlv (url) {
     
                let videoDome = `<video autoplay controls muted width= '100%'  height="100%" id="videodomes" ref= 'videodomes' style= "object-fit: fill"></video>`   
                document.getElementById("mapVideo").innerHTML = videoDome;
                let videoElement = document.getElementById('videodomes');  
                let player = this.$flv.createPlayer({
                    type: 'flv',
                    url: url,
                    isLive: true, // 实时流
                    hasAudio: false,
                    hasVideo: true
                    // hasAudio: true
                });
                player.attachMediaElement(videoElement);
                player.load();
                player.play();
                this.player = player
                window.flvPlayer = this.player
            },  
          
            
        }
    }
</script>

<style lang="scss" scoped>

    
     .mapVideo_content_box{
        //  margin-bottom: 10px;
        height: 500px;
        padding: 10px;
        box-sizing: border-box;
     }
    .mapvideo_show_box{
        width: 100%;
        height: 100%;
        min-height: 184px;                     
        background-color: rgba(0,0,0,0.3);
    }

</style>