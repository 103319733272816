<template>
    <div :class="['circle_main_box', isActive? 'activeCircle': '']">
         <div class="triggerb_btn trigger auto-pool-dev-bg"  @click="open">
             <img :src="icon1" alt="">
         </div>
         <div class="icons">
             <div class="rotater" v-for="(item,i) of devList" :key="item.id">
               
                <div class="btn-icon btn">
                    <div :class="['icon_but',typeArr.includes(i) ? 'style1': 'style2']"><div class="boxBg"><span :class="typeArr.includes(i) ? 'iconfont icon-tongcai-yeweiyi': 'iconfont icon-tongcai-liuliang'"></span></div></div>
                    <span class="mt-6">{{ item.name }}</span>
                    <span :class="[typeArr.includes(i) ? 'color1': 'color2']">{{ item.value + item.unit}}</span>
                </div>
                 
                 
             </div>
         </div>
     </div>
 </template>
 
 <script>

     import config from '@/config'
     export default {
      
         props:{
            pointId: String
         },
         data() {

             return {
                typeArr:[0,1,2,7],
                icon1:'',
                isActive: false,
                devActiveId: '',
                infoList: [],
                paramData: null,
                paramShowType: config.paramShowType,
                positionInfo:{
                    left: [0,1,6,7],
                    right: [2,3,4,5]
                },
                devList: [
                    {
                       name: '实时水温',
                       key: 'waterTemp',
                       unit: '℃',
                       value: 0
                    },
                    {
                       name: '当前液位',
                       key: 'thisLiquidLevel',
                       unit: 'm',
                       value: 0
                    },
                    {
                       name: '目标液位',
                       key: 'targetLiquidLevel',
                       unit: 'm',
                       value: 0
                    },
                    {
                       name: '实时流量',
                       key: 'realTimeLevel',
                       unit: 'm³/h',
                       value: 0
                    },
                    {
                       name: '今日用量',
                       key: 'dayDosage',
                       unit: 'm³',
                       value: 0
                    },
                    {
                       name: '当月用量',
                       key: 'moonDosage',
                       unit: 'm³',
                       value: 0
                    },
                    {
                       name: '当年用量',
                       key: 'yearDosage',
                       unit: 'm³',
                       value: 0
                    },
                    {
                       name: '实时管压',
                       key: 'realTimePipePressure',
                       unit: 'mpa',
                       value: 0
                    }
                ]  
                
             }
         },
         watch:{
          
            pointId:{
                handler(val){
                    console.log(val)
                  if(val) this.IotPointCisternDeviceDetails()
                },
                immediate: true
            }
         },
         computed:{
          
            
            statusStyle(){
                return status => status && status == config.devStatus.online ?
                                 'onlineBut': status == '2' ?  
                                 'standbyColor' : 'offLineColor'      
            }
         },
         created(){
             // this.infoList = this.devList
            //  this.$nextTick(()=>{
            //      setTimeout(() => {
            //         this.isActive = this.active 
            //      },300)
                  
            //  })
           
             this.icon1= require("@/assets/imgs/autoPool/"+this.$themeCode +"/pool.png")
             
         },
         methods:{
             async IotPointCisternDeviceDetails(){
                 const res = await this.$api.IotPointCisternDeviceDetails({ id: this.pointId })
                 if(res){
                    this.devList.map(item => {
                       item.value = res ?.[item.key] || 0
                    })
                      this.$nextTick(()=>{
                            setTimeout(() => {
                                this.isActive = true
                            },300)
                            
                        })
                 }
             },
             open() {
                this.isActive =!this.isActive
                this.devActiveId = ''
             },
             devChange(item){
                
                this.devActiveId = item.id
                this.paramData = item
             //    item.visible = true
                
             },
             closePopover(item){
                 item.visible= false
                 this.devActiveId = ''
             }
         }
     }
 </script>
 
 <style lang="scss" scoped>
 .style1{
     background-color: #00C497;
     span{
        color: #00C497;
        font-size: 30px;
     }
 }
 .style2{
    background-color: #00AEFF;
    span{
        color: #00AEFF;
        font-size: 30px;
     }
 }
 .color1{
    color: #00C497;
 }
 .color2{
    color: #00AEFF;
 }
 .circle_main_box,
 .circle_main_box .btn .iconfont
  {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translateX(-50%) translateY(-60%);
    
 }
 .circle_main_box {
    width: 285px;
    height: 300px;
    //  .icons{
    //      .rotater{
    //          .btn{
                
                
    //          }
    //      }
    //  }
 }
 .triggerb_btn{
    position: absolute;
     top: 0;
     left: 0;
     width: 285px;
    height: 300px;
     border-radius: 50%;
     opacity: 0;
     z-index: -10;
     cursor: pointer;
     transition: opacity 1s, z-index 0.3s, transform 1s;
     transform: translateX(0);
     display: flex;
     align-items: center;
     justify-content: center;
     
 }
 .onlineBut{
    
     color: var(--el-color-primary);
 }
 .mt-6{
   margin-top: 6px;
 }
//  .trigger_butbox{
//     background: url('../../assets/imgs/index/default/circle_map.png') no-repeat;
//     background-size: 100% 100%;
//     animation:turn 3s linear infinite;
//  }
 
 .circle_main_box .btn {
     position: absolute;
     top: 0;
     left: 0;
    //  width: 4em;
    //  height: 8em;
     
     opacity: 0;
     z-index: -10;
     cursor: pointer;
     transition: opacity 1s, z-index 0.3s, transform 1s;
     transform: translateX(0);
     font-size: 12px;
     .icon_but{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: relative;
        & > div {
            width: 58px;
            height: 58px;
            border-radius: 50%;
          
        }
     }
     display: flex;
     flex-direction: column;
     align-items: center;
    justify-content: center;
    
 }
//  .circle_main_box .btn .iconfont {
//      font-size: 36px;
//      transition: color 0.3s;
//  }
 // .circle_main_box .btn:hover .iconfont {
     
 // }
 .circle_main_box .triggerb_btn.trigger {
     opacity: 1;
     z-index: 100;
     cursor: pointer;
     transition: transform 0.3s;
 }
 .circle_main_box .triggerb_btn.trigger:hover {
     transform: scale(1.2);
 }
 .circle_main_box .devIconActive{
     background-color: var(--el-color-primary) !important;
     color: #fff !important;
     transition: background-color 0.3s;
     
 }
 .circle_main_box .rotater {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     transform-origin: 50% 50%;
 }
 .circle_main_box .rotater a {
     color: #fff;
     display: block;
 }
 .circle_main_box.activeCircle .btn-icon {
     opacity: 1;
     z-index: 50;
 }
 .rotater:nth-child(1) {
     transform: rotate(-90deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(1) .btn-icon {
     transform: translateY(-80px) rotate(90deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        right: 0;
     }
 }
 .rotater:nth-child(2) {
     transform: rotate(-43deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(2) .btn-icon {
     transform: translateY(-60px) rotate(43deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        right: 0;
     }
 }
 .rotater:nth-child(3) {
     transform: rotate(14deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(3) .btn-icon {
     transform: translateY(-40px) rotate(-14deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        right: 0;
     }
 }
 .rotater:nth-child(4) {
     transform: rotate(64deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(4) .btn-icon {
     transform: translateY(-43px) rotate(-62deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        left: 0;
     }
 }
 .rotater:nth-child(5) {
     transform: rotate(113deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(5) .btn-icon {
     transform: translateY(-65px) rotate(-112deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        left: 0;
     }
 }
 .rotater:nth-child(6) {
     transform: rotate(154deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(6) .btn-icon {
     transform: translateY(-85px) rotate(-153deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        left: 0;
     }
 }
 .rotater:nth-child(7) {
     transform: rotate(189deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(7) .btn-icon {
     transform: translateY(-83px) rotate(-189deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        left: 0;
     }
 }
 .rotater:nth-child(8) {
     transform: rotate(230deg);
 }
 .circle_main_box.activeCircle .rotater:nth-child(8) .btn-icon {
     transform: translateY(-80px) rotate(-229deg);
     .icon_but div{
        position: absolute;
        bottom: 0;
        right: 0;
     }
 }
 
 </style>