<template>
 <div style="width: 100%;">
    <vue-seamless-scroll v-if="list && list.length" :data="list" class="data_warp_box" ref="scrollRef" :class-option="defaultOption">
    
        <ul class="nowDataList">
            <li class="nowDataItem" v-for="(item,i) of list" :key="i">
                <div>
                    <div :style="paramOutIconHanlde(i)"></div>
                    <span :class="['iconfont', item.icon]" :style="paramInIconHanlde(i)"></span>
                </div>
                <div>
                    <p>
                        <span :class="item.name !== '风向'?'paramNub':'paramWind'">{{ paramValueHandle(item) }}</span>
                        <span>{{ item.name === '风向'?'': item.enUnit }}</span>
                    </p>
                    <p>{{ item.name }}</p> 
                </div>
            </li>
        </ul>
    </vue-seamless-scroll>
    <div class="noData" v-else>
        <img :src="noDataUrl" alt="">
        <p>暂无数据</p>
      </div>
</div> 
  </template>
  
  <script>
    export default {
      props: {
        list: {
          type: Array,
          default: () => [],
        },
      },
      data() {
        return {
          paramColorList:[
                    {
                       iconUrl: require('@/assets/imgs/monitoring/default/circle.png'),
                       iconColr:'#00ffc5'
                    },
                    {
                       iconUrl: require('@/assets/imgs/monitoring/default/circle1.png'),
                       iconColr:'#00ccff'
                    },
                    {
                       iconUrl: require('@/assets/imgs/monitoring/default/circle2.png'),
                       iconColr:'#ffa800'
                    },
                    {
                       iconUrl: require('@/assets/imgs/monitoring/default/circle3.png'),
                       iconColr:'#a54ed7'
                    },
                    {
                       iconUrl: require('@/assets/imgs/monitoring/default/circle4.png'),
                       iconColr:'#435ae6'
                    }
           ],
           noDataUrl: require('@/assets/imgs/noData/no_data2.png'),
        };
      },
      computed: {
            
            defaultOption () {
                return {
                    step: 0.5, // 数值越大速度滚动越快
                    limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: false, // 是否开启鼠标悬停stop
                    direction: 2, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                
                    // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    // waitTime: 5000 // 单步运动停止的时间(默认值1000ms)
                }
            },
            paramOutIconHanlde(){
                 return index => {
                    let i = this.paramColorList.length > index ? index : index%4
                    let data = this.paramColorList[i]
                    return {
                        backgroundImage: `url(${data.iconUrl})`,
                        backgroundRepeat: 'no-repeat',
                    }
                 }
            },
            paramInIconHanlde(){
                return index => {
                    let i = this.paramColorList.length > index ? index : index%4
                    let data = this.paramColorList[i]
                     return {
                         color: data.iconColr,
                         fontSize: '30px'
                     }
                }
            },
            paramValueHandle(){

                return item => {
                    if(item.name.includes('风向')){
                    
                    let valArr = [
                        {
                            max:112.5,
                            min:67.5,
                            name: '北风'
                        },
                        {
                            max:157.5,
                            min:112.5,
                            name: '西北风'
                        },
                        {
                            max:202.5,
                            min:157.5,
                            name: '西风'
                        },
                        {
                            max:247.5,
                            min:202.5,
                            name: '西南风'
                        },
                        {
                            max:292.5,
                            min:247.5,
                            name: '南风'
                        },
                        {
                            max:337.5,
                            min:292.5,
                            name: '东南风'
                        },
                        {
                            max:22.5,
                            min:337.5,
                            name: '东风'
                        },
                        {
                            max:67.5,
                            min:22.5,
                            name: '东北风'
                        }
                    ]
                    let val = parseInt(item.latestValue)
                    let resultName = '无风'
                
                    valArr.some(item => {
                        
                        if(val > 337.5 || val <= 22.5){
                            resultName= '东风'
                            return true
                        } 
                        if(val> item.min && val<= item.max){
                            resultName= item.name
                            return true
                        }
                    })
                    return resultName
                
                    }else {
                        return item.latestValue
                    }
                }
            },
      }
    };
  </script>
  
  <style lang="scss" scoped>
    .data_warp_box {
    
      flex: 1;
      height: 100px;
      overflow: hidden;
      .nowDataList {
        height: 100px;
        display: flex;
        & > li {
                   min-width: 261px;
                   height: 100%;
                   position: relative;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   & > div:first-child{
                       width: 60px;
                       height: 60px;
                       position: relative;
                       & > div{
                          width: 100%;
                          height: 100%;
                          animation:turn 4s linear infinite;
                       }
                       & > span{
                          position: absolute;
                          left: 50%;
                          top: 50%;
                          transform:translate(-50%,-50%);
                          
                       }                     
                   }
                   & > div:last-child{
                       margin-left: 10px;
                       & > p:first-child {
                           color: #fff;
                           font-size: 14px;
                           .paramNub{
                                font-size: 30px;
                                font-family: 'agencyr';
                            }
                            .paramWind{
                                font-size: 22px;
                            }
                       }
                       & > p:last-child {
                           font-size: 14px;
                           color: rgba(255,255,255, 0.4);    
                       }
                   }
        }
        & > li::before{
            width: 1px;
            height: 80px;
            display: block;
            background: linear-gradient(
            to top, 
            rgba(0,0,0,0) 0%,  
            #fff 50%, 
            rgba(0,0,0,0) 100%);
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -40px;
            content: '';
        }
      }
    }
    .paramNub {
      font-size: 24px;
    }
    .paramWind {
      font-size: 18px;
    }
    .noData{
      
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-size: 14px;
      & > img {
         width: 80px;
         margin-right: 25px;
      }
    }
  </style>
  