<template>
  <div>
      
 
      <div class="ctl_list" v-if="ctlDevList && ctlDevList.length">
        <div v-for="(item,i) of ctlDevList" :key="item.id"  :class="isActiveClass(item)">
                <div class="top_box boxBg">
                    <div>
                      
                        <span :class="actuatorStutasColor(item)"></span>
                        <span>{{ item.name }}</span>
                    </div>
                  
                </div>
                <div class="content_box boxBg">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <template v-if="isOpenIcon(item)">
                            <el-image :src="gifUrl(item.workIcon)" class="hn-img">
                              <div slot="error" class="clt-icon-error-slot iconfont icon-zhanweifu">
                              </div>
                            </el-image>
                        </template>
                        <template v-else>
                            <span :class="[ item.channelStatus > 2 ? 'standbyColor' : '',item.icon || 'iconfont icon-zhanweifu'] "></span>
                        </template>
                        <el-progress v-if="item.isReversible && !isOldDev" :percentage="item.openPercent" :color="percentageColor(item)" style="width:70%;margin-top: 4px;" :format="nub => progressFormat(nub,item)"></el-progress>
                        <p v-else :class="[actuatorStatusTextColor(item),'font-12']" style="margin-top: 4px;">{{ actuatorStatusText(item) }}</p>
                        
                    </div>
                    <div v-if="autoCtlMode" class="fun_but_box">
                      
                        <div :class="isDisableBut(item,'open') ? 'offLineColor': 'cp'" @click="ctlOnOff(item,{funValue: 1,openPercent: 100},item.openChannelName)">{{ item.openChannelName || '打开' }}</div>
                        <div :class="isDisableBut(item,'colse') ? 'offLineColor': 'cp'" @click="ctlOnOff(item,{funValue: item.isReversible ? 2 : 0,openPercent: 100},item.closeChannelName)">{{ item.closeChannelName || '关闭' }}</div>
                        <template v-if="item.isReversible">
                          <div class="cp" @click="ctlOnOff(item,{funValue: 0,openPercent: 0},'暂停')">暂停</div>      
                          <el-dropdown @command="data => ctlOnOff(item,data,data.name)" size="small" v-if="item.iotActuatorFuns && item.iotActuatorFuns.length">
                            <el-button size="mini" type="text" style="color: #ccc;" icon="el-icon-more"></el-button>
                            <el-dropdown-menu slot="dropdown">
                              <template v-for="(act, idx) in item.iotActuatorFuns">
                                <el-dropdown-item  :command="act" :disabled="act.disabled" :icon="act.icon" :key="idx">{{act.name}}</el-dropdown-item>
                              </template>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      
                    </div>
                </div>
                <loading :isShow="item.isLoading" class='font-14' :text="loadingText(item)">
                  <div class="d-flex flex-column align-items-center" v-if="item.isReversible && item.showPause && !isOldDev">
                    <div class="d-flex">
                      <el-button size="small" type="primary" @click="pauseCtl(item.actuatorCode)" :loading="item.pauseLoading" v-if="item.isCtlPause">暂停</el-button>
                      <template v-else>
                        <el-button size="small" type="primary" @click="finishCtl(item.actuatorCode)">完成</el-button>
                        <el-button size="small" type="primary" @click="nextCtl(item.actuatorCode)">继续</el-button> 
                      </template>
                    </div>
                    <!-- <el-progress v-if="item.isReversible" :percentage="item.openPercent" :color="percentageColor(item)" style="width:160px;margin-top: 10px;" :format="nub => progressFormat(nub,item)"></el-progress> -->
                  </div>
                  
                </loading> 
                <template v-if="item.isErrorLoading">
                  <loading isShow :isAnimale="false">
                    <div class="d-flex align-items-center flex-column justify-content-center font-14">
                      <span class="el-icon-circle-close font-40 errorColor"></span>
                      <p class="tc mt-10">控制失败，请稍后再试</p>
                    </div>
                  </loading>
                </template>
            
        </div>
      </div>
      <div class="noData" v-else>
        <img :src="noDataUrl" alt="">
        <p>暂无数据</p>
      </div>
  </div>
</template>

<script>
    import ctrPwdMixin from '@/mixins/ctrPwd'
    import ctlMixins from '../../dev-control/components/actuator-list/ctlMixins.js'
    import actuatorStutas from '../../dev-control/components/actuatorStutas'
    import config from '@/config'
    import loading from '../../dev-control/components/actuator-list/components/loading.vue'
    // import pwdFrom from '../../dev-control/components/pwdFrom.vue'
    export default {
        mixins: [ctrPwdMixin,ctlMixins,actuatorStutas],
        components:{
            loading
        },
        props:{
            ctlDevList: {
              type: Array,
              default: () => []
           },
           isOldDev: {
            type: Boolean,
            default: false
           },
           devInfo:{
            type: Object,
            default: () => ({})
           },
        },
        computed:{
           
            showFunButList(){
                return list => {
                    if(list && list.length < 3) return list
                    return list.slice(0,2)
                    
                }
            },
            moreFunButList(){
                return list => {
                    if(list && list.length < 3) return []
                    return list.slice(2)
                }
            },
            autoCtlMode(){
                console.log(this.devInfo.autoCtlMode == 0 ? true : false)
                //  是否自动模式
                return this.devInfo.autoCtlMode == 0 ? true : false
            },
            isOnline() {
                let info = this.devInfo
                return info ? info.onlineStatus : false
            },
            devStatus(){
                let data = this.devInfo
                return data && data.onlineStatus !== this.onlineData ? false : true
            },
        },
        data(){
            return {
             
                onlineData: config.devStatus.online,
                isAloneCtl: true,
                activeRowData: null,
                againReqCount: 0,
                taskQueueInfo: [], // 任务队列信息
                maxFunCount: 3,
                dialogVisible: false,
                activeItem: null,
                noDataUrl: require('@/assets/imgs/noData/no_data2.png'),
            }
        },
        methods:{
         toDiolg(item){
           this.dialogVisible = true
           this.activeItem = item
        },
        handleClose(){
          this.dialogVisible = false
            this.activeItem = null
        },
        // 是否激活当前功能按钮
        isActiveFunBut(item,butInfo){
      
            let code = butInfo.funValue == 0 ? item.isReversible ? 2 : 0 : butInfo.funValue
            return  item.channelStatus == code   
        },
        sourceCallback(frame){
          const ObjFrame = JSON.parse(frame.body)
          // 1.过滤非当前设备的控制信息
          if(this.devInfo.devCode !== ObjFrame.devId) return

          ObjFrame.executors.map(item => {
     
             // 1.判断单双通道
             const isReversible = this.isReversible(item.no)
             // 2.判断是其他设备控制结果，还是本设备控制结果
             let queueItemData = this.getTaskQueueInfo(item.no)

             if(isReversible){  // 正反转设备
                if(queueItemData){  // 表示正在队列控制的设备
                  // 当当前执行器请求控制状态为0，并且开合度相等时，判断为用户点击暂停按钮，直接关闭动画
                  if(item.percentage == queueItemData.rowData.openPercent && queueItemData.reqData.openType == 0) {
                      // 更新指定执行器数据
                      this.updateActuatorStatus(item)
                      // 删除已经完成的执行器队列
                      this.delTaskQueueInfo(queueItemData.no)
                      return
                  }
                  // 当percentage为100时，表示开关完成，更新状态动作
                  if(item.percentage == queueItemData.rowData.openPercent) return queueItemData.rowData.showPause = true
                    
                  if(item.percentage == queueItemData.reqData.percent){
                     item.status = item.status > 2 ? item.status : item.percentage == 100 ? 1 : item.percentage == 0 ? 2 : 0
                     // 更新指定执行器数据 
                     this.updateActuatorStatus(item)
                     // 删除已经完成的执行器队列
                     this.delTaskQueueInfo(queueItemData.no)
                  } else if( item.status == 0 && queueItemData.rowData.pauseLoading) { //  表示控制成功
                    
                        // 当处于暂停状态，并且按钮动画开启时，执行是否继续
                        // 4.关闭暂停按钮动画
                        queueItemData.rowData.pauseLoading = false
                        // 5.隐藏暂停按钮
                        queueItemData.rowData.isCtlPause = false
                        // 更新百分比
                        queueItemData.rowData.openPercent = item.percentage
                        // 将返回值绑定队列中
                        queueItemData.executors = item
                    
                  }else{

                      // 关闭加载动画
                      queueItemData.rowData.isLoading = false
                      // 开启控制失败动画
                      // queueItemData.rowData.isErrorLoading = true
                      // 延迟2秒关闭
                      setTimeout(()=>{
                          // 更新指定执行器数据
                          this.updateActuatorStatus(item)
                          // 删除已经完成的执行器队列
                          this.delTaskQueueInfo(queueItemData.no)
                      },2000)
                  }
                }else{  // 表示外部环境发起的控制动作
                    let actuatorInfo = this.getActuatorInfo(item.no)
                    // 当返回百分比和当前执行器百分比不相等时，说明状态改变，更新数据
                    if(item.percentage !== actuatorInfo.openPercent){
                      // 转化status
                      item.status = item.status > 2 ? item.status : item.percentage == 100 ? 1 : item.percentage == 0 ? 2 : 0
                      // 更新指定执行器数据 
                      this.updateActuatorStatus(item)
                    } 
                }
               
             }else{ // 单通道设备
                if(queueItemData){ // 当queueItemData存在，说明是本设备控制状态
                 
                  // 判断是否执行器是否执行成功
                  if(item.status == queueItemData.reqData.openType){
                  
                    // 更新指定执行器数据
                    this.updateActuatorStatus(item)
                    // 删除已经完成的执行器队列
                    this.delTaskQueueInfo(queueItemData.no)
         
                  }else{ // status结果不匹配，判断为控制失败
                  
                        // 关闭加载动画
                        queueItemData.rowData.isLoading = false
                        // 开启控制失败动画
                        queueItemData.rowData.isErrorLoading = true
                        // 延迟2秒关闭
                        setTimeout(()=>{
                            // 更新指定执行器数据
                            this.updateActuatorStatus(item)
                            // 删除已经完成的执行器队列
                            this.delTaskQueueInfo(queueItemData.no)
                        },2000)
                  }
                }else{
                  // 其他设备控制结果，更新列表信息
                  this.updateActuatorStatus(item)
                }
             }
   
          })          
        },
        isNeedPwd(){ 

              let { isSetPwd, devId } = this.devInfo
      
              let result = false
              if( isSetPwd ){
                  result = this.isPwdPass(devId)  // 验证设备密码是否存在或过期
                  result = !result   
              }
              return result
        },
        
        // 设备控制接口
        async reqIotDeviceCtrlChannel(data){
              try{
                  const res = await this.$api.IotDeviceCtrlChannel(data)
                  return res
              }catch{
                    return false
              }
    
        },
        // 批量控制入口
        batchCtl(info){
          if(this.isOnline !== 9) return this.$msg.info('设备离线中，无法操作') 
          let {parentInfo, childInfo} = info
          let actuators = [] 
          let aloneReqDatas = []
          let isNeedPwd = this.isNeedPwd()
          this.ctlDevList.map(item => {
            if(item.executorTypeId == parentInfo.id) {
              // 如果设备当前状态和需要批量控制状态相等时，不需要控制
              const openPercent = item.isReversible ? childInfo.funValue == 0 ? -1 : childInfo.funValue == 2 && childInfo.openPercent == 100 ? 0 : childInfo.openPercent : childInfo.openPercent
               if((item.isReversible && item.openPercent == openPercent) || (!item.isReversible && item.channelStatus == childInfo.funValue)) return  
               // 1.初始化控制请求值
               let data = {
                      id: item.devId,
                      actuatorId: item.id,
                      devCode: item.devCode,
                      no: item.channelNo,
                      reversalNo: item.reverseChannelNo,
                      stopNo: item.closeChannelNo,
                      openType: childInfo.funValue,
                      exeNo: item.actuatorCode,
                      delay: item.delay,
                      percent: childInfo.openPercent
                }
                // 当不需要密码时，加入队列
                if(!isNeedPwd){
                 
                  // 2.初始化执行器执行队列
                  let queueInfo = {
                      reqData: data,  // 请求数据
                      rowData: item,  // 当前执行器数据
                      isAloneCtl: false,
                      oldOpenType: data.openType, // 执行动作备份
                      oldOpenPercent: data.percent,
                      no: data.exeNo, // 执行器编号
                      isOutSide: false, // 是否是外部队列
                      ctlName: childInfo.name // 控制动作名称
                  } 
                  // 3.加入队列
                  this.addTaskQueue(queueInfo)
                }
                actuators.push({
                      actuatorId: item.id,
                      openType: childInfo.funValue
                })
                aloneReqDatas.push({
                  reqData: data,  // 请求数据
                  rowData: item,  // 当前执行器数据
                  isAloneCtl: false,
                  oldOpenType: data.openType, // 执行动作备份
                  oldOpenPercent: data.percent,
                  no: data.exeNo, // 执行器编号
                  ctlName: childInfo.name // 控制动作名称
                })
            }
          })
          if(!actuators.length) return this.$msg.info(`当前设备已全部${childInfo.name},不需要控制`)
          let data = { actuators,openPercent: childInfo.openPercent}
          if(isNeedPwd){
              data.aloneReqDatas = aloneReqDatas
              this.showDialog(pwdFrom,{},{
                  width:'400px',
                  title: '控制密码',
                  dialog:{
                    isCenter: true,
                    confirmButtonText: '提交'
                  }
                },(pwd)=> {
               
                    // 批量加入队列
                    data.aloneReqDatas.map(item => {
                      // 添加请求密码
                      item.reqData.ctrlPwd = pwd
                      this.addTaskQueue(item) 
                    })
                    // 请求批量控制
                    this.reqIotDeviceCtrlMany({ 
                      actuators: data.actuators,
                      ctrlPwd: pwd
                    })  
              })
          }else{
              let pwd = this.getPwdVal(this.devInfo.devId)
              if(pwd) data.ctrlPwd = pwd
              this.reqIotDeviceCtrlMany(data)
          } 
        },
        // 批量控制
        async reqIotDeviceCtrlMany(data){
            // 1.遍历加载执行动画
            // this.perLoading = true
            this.batchCtlLoadingAnime(true)
            const res = await this.$api.IotDeviceCtrlMany(data)
            if(res){
                
                // 2.保存设备控制密码
                if(this.devInfo.isSetPwd && res) this.addLocaCtrPwd(this.devInfo.devId,data.ctrlPwd)
                // 3.老设备处理函数
                if(res && res.length){
                  //  判断是否控制成功
                  res.map(info => {
                      // 获取当前执行器队列信息
                      let queueItemData = this.getTaskQueueInfo(null,info.actuatorId)
                      let {reqData,rowData } =  queueItemData   
                      if(info.isOk){ // 控制成功 
                        if(this.isOldDev){
                          setTimeout(()=>{ 

                              let status = 1
                              let percentage = 100
                              if( rowData.isReversible ){
                                status = reqData.openType
                                percentage = reqData.openType == 1 ? 100 : 0
                              }else{
                                status = reqData.openType
                                percentage = reqData.openType == 1 ? 100 : 0 
                              }

                              this.updateActuatorIdStatus({
                                no: info.actuatorId,
                                status,
                                percentage,
                              })
                              this.delTaskQueueInfo(queueItemData.no,info.actuatorId)
                          },1000)
                        }
                      }else{ 
                        if(this.isOldDev){
                             // 开启控制失败动画
                          rowData.isErrorLoading = true
                          // 延迟2秒关闭
                          setTimeout(()=>{
                              // 关闭加载动画
                              rowData.isLoading = false
                              rowData.isErrorLoading = false
                              // 删除已经完成的执行器队列
                              this.delTaskQueueInfo(queueItemData.no,info.actuatorId)
                          },2000)
                        }else{
                          this.updateActuatorStatus({
                            no: queueItemData.no,
                            status: rowData.channelStatus,
                            percentage: rowData.openPercent
                          })
                          // 删除已经完成的执行器队列
                          this.delTaskQueueInfo(queueItemData.no)
                        }
                        
                      }
                  })

                }
               
                
            }else{
         
              this.batchCtlLoadingAnime(false)
                // this.erroStatus = true
            }    
        }
        }
    }
</script>

<style lang="scss" scoped>
.ctl_list{
    padding: 10px;
    box-sizing: border-box;
    display: grid;
	 grid-template-columns: repeat(auto-fill,minmax(206px, 1fr));
    grid-gap: 10px;
	 grid-template-rows: 140px;
    grid-auto-rows: 140px; 
    & > div {
             
              border-radius: 18px;
              border: 1px solid transparent;
              position: relative;
              .top_box{
                  color: #fff;
                  padding: 7px 15px;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  border-radius: 18px 18px 0 0;
                  border-bottom: 1px solid #15232D;
                  
                  & > div:first-child{
                      font-size: 16px;
                      display: flex;
                      align-items: center;
                      & > span:first-child{
                          display: block;
                          width: 8px;
                          height: 8px;
                          border-radius: 4px;
                          margin-right: 5px;
                          background: #999;
                      }
                      .awaitState{
                        background: #ffa800;
                      }
                      .closeState{
                          background: #999999;
                      }
                  }
                  & > div:last-child{
                      font-size: 14px;
                  }
              }
              .content_box{
                  width: 100%;
                  height: calc(100% - 36px);
                  border-radius: 0 0 18px 18px;
                  display: flex;
                  flex-direction: column;
                //   padding: 15px 5px 15px 15px;
                  box-sizing: border-box;
                  & > div:first-child {
                      flex: 1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      & >span{
                          font-size: 40px;
                          color: #999;
                      }
                      & > .hn-img {
                          width: 40px;
                        //   height: 88px;
                      }
                  }
                  & > .fun_but_box{
                      width: 100%;
                      height: 34px;
                      font-size: 14px;
                      display: flex;
                      border-top: 1px solid #15232D;
                      & > div {
                          flex: 1;
                          height: 100%;
                          color: #fff;
                          text-align: center;
                          line-height: 34px;
                          transition: background-color 1s;
                      }
                      & > div:not(:first-child){
                        border-left: 1px solid #15232D;
                      }
                      .stop_box{
                          border-left: 1px solid #15232D;
                          border-right: 1px solid #15232D;
                      }
                      .activeText{
                          color: #fff;
                      }
                      & > div:first-child{
                          border-radius: 0 0 0 15px;
                      }
                      & > div:last-child{
                          border-radius: 0 0 15px 0;
                      }
                    //   .stop_but_box{
                    //      height: 20px;
                    //      line-height: 20px;
                    //      border-radius: 10px;
                    //      padding: 0 10px;
                    //      margin-bottom: 12px;
                    //      font-size: 12px;
                    //      border: 1px solid #fff;
                    //   }
                    //   .baseState{
                    //       background-color: rgba(#ffa800, .2);
                    //       border: 1px solid #ffa800;
                    //   }

            
                  }
              }
              
    }
}
.warnBorder{
    border: 1px solid #ffa800 !important;
    position: relative;
}
.warnBorder::after{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000,0.5); 
    border-radius: 20px;
}
.el-progress--line ::v-deep .el-progress__text {
    color: #fff;
 }
 .el-progress--line ::v-deep .el-progress-bar {
  padding-right: 45px;
  margin-right: -50px;
 }
 .noData{
     height: 140px;
    
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-size: 14px;
      & > img {
         width: 120px;
         margin-right: 25px;
      }
    }
</style>