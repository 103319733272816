<template>
    <div class="pictrue_main_box wh100 pr">
      <HnContent :hasData="hasData">
        <template slot="left">
          <!-- <HnMenu :menuDevType="menuDevType" @change="devChange"></HnMenu> -->
          <areaMenu @change="menuChange"></areaMenu>
        </template>
        <template slot="right">
          <div
           class="right_content_box video-box-bg-image wh100"
           v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" 
           >
              <div class="top_box boxBg">
                <div class="dev_title">
                  <div class="line"></div>
                  <span class="iconfont icon-caiwu-zhihuishuichi"></span>
                  <span>{{ pointName }}</span>
                </div>
                <warnBoard @update="LogInsectLampAlarmContentList" :listData="warnList" ref="warnRef"  v-if="warnList && warnList.length"></warnBoard>
              </div>
              <div class="content_box">
                 <div class="content_left">
                   
                    <div class="pr"><devInfo :pointId="pointId"></devInfo></div>
                    <div class="pr" v-if="videoList && videoList.length"><videoList :list="videoList"></videoList></div>
                 </div>
                 <div class="content_right boxScroll">
                    <div class="content_info_box boxBg"> 
                       <div class="header">
                        <div class="title">采集器数据</div>
                        <el-button size="small" class="ml-10 update_primary_but" v-if="paramDevId" @click="toPath('/monitoring')" type="primary" icon="el-icon-time" plain>历史数据</el-button>
                      </div>
                       <div class="content">
                        <div class="pageBg d-flex align-items-center param">
                          <paramScroll :list="nowDataList"></paramScroll>
                        </div>
                       </div>
                    </div>
                    <div class="content_info_box boxBg"> 
                       <div class="header"><div class="title">智能控制</div> <span class="font-24 cp" @click="toPath('/devControl')">...</span></div>
                       <div class="content">
                        <div class="pageBg ctl">
                          <autoCtl :ctlDevList="ctlList" :devInfo="devInfo" ref="actuatorRef" :isOldDev="isOldDev" @update="reqIotDeviceControlList"></autoCtl>
                        </div>
                       </div>
                    </div>
                    <div class="content_info_box boxBg"> 
                       <div class="header">
                        <div class="title">用量趋势</div>
                        <pickDay @update="dateUpdate" ref="pickDay" :isUpModel="isUpModel" :timeList="timeList" size="small"/>
                       </div>
                       <div class="content">
                        <div class="pageBg">
                          <charts  refName="chartDosage" :options='echartsOptions' class="chart_box"></charts>
                        </div>
                       </div>
                    </div>
                 </div>
              </div>
          </div>
        </template>
      </HnContent>
    </div>
  </template>
  
  <script>
  import menuFun from "@/mixins/menuFun";
  import devInfo from "./components/devInfo.vue";
  import videoList from "./components/videoList.vue";
  import paramScroll from "./components/paramScroll.vue";
  import autoCtl from './components/autoCtl.vue'
  import charts from "./components/charts.vue";
  import areaMenu from './components/areaMenu'
  import pickDay from './components/pickDay.vue'
  import warnBoard from "./components/warnBoard.vue";
  export default {
    components:{ devInfo,videoList,paramScroll,autoCtl,charts,areaMenu,warnBoard,pickDay }, 
    mixins: [menuFun],
    data () {
      return {
       
        menus: [],
        loadingText: '加载中',
        menuDevType: ["虫情设备"],
        pointId: "",
        nowDataList: [],
        echartsOptions:null,
        warnList: [],
        pointName: '',
        videoList: [],
        devInfo: null,
        ctlList: [],
        isUpModel: '',
        warnDevIds: [],
        loading: false,
        paramDevId: ''
      };
    },
    computed: {
    
      isOldDev() {
        let vCode = this.devInfo ?.protocolVersion; // 大于0 是新的
        return vCode <= 0;
      },
      devId(){
         return this.devInfo ?.devId
      },
      timeList(){
         return this.isUpModel == 1 ? [
              {
                name: '日',
                code: 1
              },
              {
                name: '月',
                code: 2
              },
              {
                name: '年',
                code: 3
              }
         ]:[
             {
                name: '月',
                code: 2
              },
              {
                name: '年',
                code: 3
              }
         ]
         
      }
    },
 
    created(){

      this.global_websocket_connect()

    },
    destroyed(){
      this.$store.commit('disconnectClient') 
    },
    methods: {
      toPath(path){
           this.$router.push({path,query: {devId: this.paramDevId}})
      },
       // 获取告警内容
       async LogInsectLampAlarmContentList() {
        const res = await this.$api.LogGetManyDevCodeAlarmList({ num: 20, devIds: this.warnDevIds,alarmTypes: [1,4,5,3,6] });
        if (res && res.length) {
          this.warnList = res || [];
          this.$nextTick(() => {
            this.$refs.warnRef?.updateData();
          });
        } else this.warnList = [];
      },
      async dateUpdate (data) {
       
        let reqData = Object.assign({pointId: this.pointId},data)
         const res = await this.$api.IotDischargeAnalysisList(reqData)
         this.echartsOptions = {
          xAxis: res ?.xData || [],
          yAxis: res ?.yData || []
        }
      },
      menuChange(val){
        let arrVal = val[0].split('-')
        const areaId = arrVal[1]
        this.pointId = arrVal[0]
        let { name, isUpModel } = this.getPointInfos(areaId,val[1]) 
        this.pointName = name
        this.isUpModel = isUpModel
        this.getIotDevPointDeviceList()
        this.$nextTick(this.$refs.pickDay.update)
       
      },
      getPointInfos(areaId,list){
          let result = null
          list.some(item => {
             if(item.id == areaId){
                item.children.some(point => {
                    if(point.id == this.pointId){
                        result = point
                        return true
                    }
                })
                return true
             }
          })
          return result
      },
   
      // 获取设备参数
      async getIotDevPointDeviceList(){
        this.loading = true
         const res = await this.$api.IotDevPointDeviceList({ id: this.pointId, paramType: 2})
       
         if(res && res.length){
            let activeDevInfo = null
            let paramInfo = null
            let videoList = []
            let warnDevIds = []
             res.map(item => {
                if(item.showType == 4 && !activeDevInfo){
                 
                  if (this.devInfo) this.pauseTopic(this.devInfo.devCode);
                  activeDevInfo = item
                  this.devInfo = item
                  warnDevIds.push(item.devId)
                  this.startTopic(); // 订阅设备
                  this.reqIotDeviceControlList()
                  
                }
                if(item.showType == 1 && !paramInfo){
                     this.nowDataList = item.data ? JSON.parse(item.data) : []
                     paramInfo = item
                     warnDevIds.push(item.devId)
                    
                }
                if(item.showType == 2){
                    item.loading = true
                    item.img = ''
                    videoList.push(item)
                }
             })
             this.videoList = videoList
             this.warnDevIds = warnDevIds
             this.paramDevId = paramInfo ?.devId
             if(!activeDevInfo){
                if (this.devInfo) this.pauseTopic(this.devInfo.devCode);
                this.devInfo = null
                this.ctlList = []
             }
             if(!warnDevIds || warnDevIds.length) this.warnList = []

             this.LogInsectLampAlarmContentList()
         }else{
            if (this.devInfo) this.pauseTopic(this.devInfo.devCode);
            this.devInfo = null
            this.nowDataList = null
            this.videoList = []
            this.warnDevIds = []
            this.warnList = []
            this.paramDevId = ''
            this.ctlList = []
         }
         setTimeout(() => {
          this.loading = false
        },300)
      },
       // 设备执行器查询
       async reqIotDeviceControlList() {
       
        const res = await this.$api.IotDeviceControlList({ devId: this.devId });
        // if (this.updateLoading) {
        //   this.$refs.actuatorRef.taskQueueInfo = [];
        //   setTimeout(() => {
        //     this.updateLoading = false;
        //     this.$msg.success("刷新成功");
        //   }, 500);
        // }
        const isData = res && res.length;
        if (isData)
          res.forEach((item) => {
            item.visbise = false; // 控制智能控制表单弹窗是否显示
            item.isLoading = false; // 控制执行器执行动画是否显示
            item.pauseLoading = false; // 控制暂停按钮加载动画是否显示
            item.isCtlPause = true; // 控制暂停按钮是否显示
            item.isErrorLoading = false; // 控制失败加载动画
            item.showPause = false; // 是否显示暂停按钮
            return item;
          });
        this.ctlList = isData ? res : [];
       
        // this.$nextTick(()=>{
        //   this.$refs.actuatorRef.updateStomp()
        // })
      },
       // 开始订阅
       startTopic() {
           if (this.isOldDev) return;

          this.global_websocket_receive_message(this.devInfo.devCode, this.sourceCallback);
        },
        // 暂停订阅
        pauseTopic(topic) {
          if (this.isOldDev) return;
          this.global_websocket_unsubscribe(topic);
        },
       // 订阅回调
       sourceCallback(frame) {
          this.$refs.actuatorRef?.sourceCallback(frame);
        },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .pictrue_main_box {
    .right_content_box {
      border-radius: 8px;
      box-sizing: border-box;
      padding: 30px 40px 30px 40px;
      .top_box{
          height: 60px;
          border-radius: 10px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .dev_title{
            display: flex;
            align-items: center;
            font-size: 16px;
            .iconfont{
               font-size: 30px;
               color: var(--el-color-primary);
               margin: 0 10px;
            }
            .line{
               width: 2px;
               height: 20px;
               background-color: var(--el-color-primary); 
            }
          }
      }
   
  
      .content_box {
        height: calc(100% - 80px);
        display: flex;
        .content_left{
         
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          & > div:first-child{
             flex: 1;
          }
          & > div:last-child{
             height: 140px;
          }

        }
        .content_right{
            width: 962px;
            height: 100%;

            // display: grid;
            // grid-template-columns: repeat(1, 1fr);
            // grid-gap: 20px;
            // grid-template-rows: repeat(3, 1fr);
            .content_info_box{
                border-radius: 10px;
                .header{
                   height: 50px;
                   display: flex;
                   align-items: center;
                   justify-content: space-between;
                   padding-right: 20px;
                   box-sizing: border-box;
                   .title{
                      font-size: 16px;
                      padding-left: 14px;
                      border-left: 1px solid var(--el-color-primary);
                   }
                }
                .content{
                
                   padding: 0 20px 20px 20px;
                   .param{
                      min-height: 102px;
                   }
                   .ctl{
                     min-height: 162px;
                   }
                   & > div{
                  
                    border-radius: 10px;
                   }
                }

                
            }
            .content_info_box:not(:first-child){
                 margin-top: 20px;
            }
        }
        // width: 100%;
        // display: flex;
        // background-image: url("../../../assets/imgs/control/default/decoration_bg.png");
        // background-repeat: no-repeat;
        // background-size: contain;
        // background-position: 50% 50%;
        // padding: 20px 0;
        // box-sizing: border-box;
  
   
  
      
  
     
  
  
      }
    }
    .chart_box{
      width: 100%;
      height: 132px;
    }
    
  }
  </style>
  