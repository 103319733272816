<template>
    <div class="dateSearch_box">
    
        <ul :class="['boxsubBg', size == 'small' ? 'dateType_mini_box': 'dateType_box']">
                <li 
                :class="[ timeType == item.code? 'active':'', 'weather-cp']"
                v-for="item of handleDate"
                :key="item.code"
                @click="timeChange(item.code)"
                >{{ item.name }}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'HnDateSearch',
        props:{
           timeList:{
               type: Array,
               default:()=> []
            },
            defaultData:{
                type:[ Array , Number]
            },
            isPage:{
                type: Boolean,
                default: false
            },
            size:{
                type: String,
                default: 'medium'
            }
        },
        data(){
            return {
                searchDateVal:'',
                timeType: 1,
             
            }
        },
        created(){
           if(this.defaultData){
               if(typeof this.defaultData == 'array'){
                   this.searchDateVal = this.defaultData
                   return
               }
               if(typeof this.defaultData == 'number' && this.defaultData > 0){
                   this.timeType = this.defaultData
               }
           }else{
                let timeListLength = this.handleDate.length  
                if(timeListLength) {
                    this.timeType =  this.handleDate[0].code
                }
           }
        
        },
        computed:{
           handleDate(){
               if(this.isPage) {
                  let isHasAllVal = this.timeList.some(item=> item.code == 0)
                  if(!isHasAllVal) return this.timeList.concat([{name: '全部',code: 0}])
               }
               return this.timeList
           }
        },
        methods:{
           update(){
              let timeListLength = this.handleDate.length  
              if(timeListLength) {
                    this.timeType =  this.handleDate[0].code
              }
              this.timeChange(this.timeType)
           },
           timeChange(code){
               let page = this.isPage? { pageIndex: 1 } : {}
               let data = {
                   
                   type: code,
               }
               Object.assign(data,page)
               this.searchDateVal= ''
               this.timeType = code
               this.$emit('update',data)
           },
          
        }
    }
</script>

<style lang="scss" scoped>
.dateSearch_box{
     display: flex;
    align-items: center; 
    .date_picker_box{
        margin-right: 15px;
    }
    ul.dateType_box{
        display: flex;
        //    width: 214px;
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        color: #fff;
        border-radius: 17px;
        
        
        & > li {
            padding: 0 28px;
            border-radius: 17px;
            
        }
        & > li:not(:last-child){
            margin-right: 8px;
        }
    
    }
    ul.dateType_mini_box{
            display: flex;
        //    width: 214px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            color: #fff;
            border-radius: 15px;
            margin-left: 15px;
            & > li {
                padding: 0 18px;
                border-radius: 15px;
                
            }
            & > li:not(:last-child){
                margin-right: 8px;
            }

    }
}
</style>