<template>
    <div class="box_main_info">
      
         <template v-if="list && list.length">
          <div :class="['content_info',isOpen? 'open':'']">
            <div class="list">
                <div v-for="item in videoList" :key="item.id">
                    <!-- borderBase -->
                    <div 
                    class="img_box boxBg"
                    v-loading="item.loading"
                    element-loading-text="加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)" 
                    >
                       <template v-if="item.img">
                        <el-image style="width: 100%; height: 100%"  :src="hanldeImg(item.img)"  fit="cover">
                            <div slot="error" class="image-slot boxBg">
                            <!-- <i class="el-icon-picture-outline"></i> -->
                            </div>
                        </el-image>
                        <!-- <img :src="hanldeImg(item.img)"  alt=""> -->
                       </template>
                        <div class="layer_box" @click="openVideo(item)">
                            <span class="iconfont icon-bofang"></span>
                            <p class="video_title">{{ item.devName }}</p>
                        </div>
                    </div>
                  
                </div>
            </div>
            <!-- <div class="pages_box">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="animelFromInfo.pageIndex"
                    :page-size="animelFromInfo.pageSize"
                    layout="total, prev, pager, next"
                    class="pageInfo"
                    background
                    :total="picTotal"
                >
                </el-pagination>
            </div> -->
          </div>  
          <div class="arrow_box" ><span @click="toOpen" :class="['iconfont', 'icon-jiantouxia', 'baseColor',isOpen ? 'arrow_icon': '','cp']"></span></div> 
         </template>
         <hnEmptyData size="small" v-else></hnEmptyData>
    </div>
</template>

<script>
    import { imgAddHost } from '@/libs/util.js'
    import videoPlay from './videoPlay.vue'
    export default {
        props:{
        
           list:{
             type: Array,
             default:() => []
           }
        },
        data(){
           return {
              isOpen: false,
             
              picTotal: 0,
              videoList:[],
              searchParamDateVal: null
           }
        },
        watch:{
           list:{
              handler(val){
        
                 if(val && val.length){
                    this.videoList = val
                    let codes = val.map(item => item.devCode)
                    this.VideoPhotographImg(codes)
                 }else{
                    this.videoList = []
                 }
              },
              immediate:true
           }
        },
        computed: {
           
            isShowMore(){
                let length = this.list ?.length
                return  length > 2 ? true : false 
            },
            hanldeImg(){
                return imgs => {
                    console.log(imgs,'照片')
                    if(!imgs) return ''
                    if(imgs.indexOf(',') > -1){
                        let img = imgs.split(',')[0]
                        return imgAddHost(img)
                    }else{
                        return imgAddHost(imgs)
                    }
                }
             
            },
            
        },
        created(){
            
            // this.reqTraFarmingPageList()
        },
        methods:{
            async VideoPhotographImg(codes){
                const res = await this.$api.VideoPhotographImg({ devCodes: codes})
                if(res && res.length){
                    console.log(this.videoList)
                    this.videoList.map(item => {
                        res.some(code => {
                            if(code.devCode == item.devCode){
                                item.img = code.imageUrl
                                item.loading = false
                                return true
                            }
                        })
                    })
                    console.log(this.videoList,'asdfhkjghjkj')
                }
            },
            openVideo(row){
                this.showDialog(videoPlay,{props:{
                     videoData: row.data,
                     typeKey: row.vendorName == '大华' ? 'hls' :'flv'
                  }},{
                  width:'800px',
                  title: `${row.devName} - 实时视频`,
                  
                  dialog:{
                    isCenter: true,
                    isBut: false
                  }
                },()=> {
                   
                })
            },
            hanldeTime(time){
                if(!time) return
                return time.split(' ')[0] 
            },
            // hanldeImg(imgs){
            //     console.log(imgs,'照片')
            //   if(!imgs) return ''
            //   if(imgs.indexOf(',') > -1){
            //     let img = imgs.split(',')[0]
            //     return imgAddHost(img)
            //   }else{
            //     return imgAddHost(imgs)
            //   }
            // },
            toOpen(){
                this.isOpen = !this.isOpen 
            },
            // handleCurrentChange(){
            //     this.reqTraFarmingPageList()
            // },
            // searchParamDateChange(){
                
            //      if(this.searchParamDateVal && this.searchParamDateVal.length){
            //          this.animelFromInfo.timeStart = this.searchParamDateVal[0]
            //          this.animelFromInfo.timeEnd = this.searchParamDateVal[1]
            //      }else{
            //          this.animelFromInfo.timeStart = null
            //          this.animelFromInfo.timeEnd = null
            //      }
            //      this.reqTraFarmingPageList()
            // },
            // async reqTraFarmingPageList(){
              
            //     this.animelFromInfo.pageSize = this.isFreeData ? 15 : 21
            //     const res = await this.$api.TraFarmingPageList(this.animelFromInfo)
            //     this.list = res ?.list
            //     this.picTotal = res ?.totalCount || 0
            // }
        }
    }
</script>

<style lang="scss" scoped>
 .el-image ::v-deep .image-slot {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.box_main_info{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    .content_info{
      height: 120px;
      overflow: hidden;
    //   background-color: red;
      transition: height 0.5s;
      .list{
        padding: 0 40px;
        height: 526px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        grid-template-rows: repeat(4, 1fr);
        .img_box{
            width: 100%;
            height: 116px;
            // border-radius: 10px;
            position: relative;
            & > img {
                width: 100%;
                height: 100%;
                // border-radius: 10px;
            }
            .layer_box{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .iconfont{
                    font-size: 40px;
                    color: #fff;
                }
                .video_title{
                    width: 100%;
                    height: 22px;
                    background-color: rgba(0,0,0,0.5);
                    font-size: 12px;
                    line-height: 22px;
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    // border-radius: 0 0 10px 10px;
                }
            }
            .layer_box:hover{
                opacity: 0.7;
                cursor: pointer;
            }
        }
      }
    }
    .open{
        height: 610px;
        padding-top: 20px;
    
        box-sizing: border-box;
        border-radius: 10px;
        background: rgba(40, 53, 63, 0.8);
    }
    .arrow_icon{
        transform: rotate(180deg) !important;
    }
    .arrow_box{
        text-align: center;
        padding-bottom: 5px;
        & > span {
            font-size: 18px;
            transform: rotate(0deg);
            transition: all 0.5s;
            display: block;
        }
    }
    .pages_box{
        text-align: center;
        margin-top: 15px;
    }
}
  
// .check-in {
//             display: block;
//       .raw {
//             height: 1em;
//             //图标默认向上
//             transform: rotate(180deg);
//             transition: all 0.5s;}
//       }
 
//       //收起
//       #check:checked ~ .box {
//           max-height: 0;
//           transform: translate(1.1);}
 
//       //旋转图标
//       #check:checked ~ .up-or-down .raw {
//           transform: rotate(360deg);}
 
//     .box {
//           //盒子默认展开
//           max-height: 70px;
//           overflow: hidden;
//           transition: max-height 0.5s;
//     }


</style>