<template>
    <div class="warn_board_box pr" ref="warnBoardRef">
      <div class="board_main_box">
          <span class="iconfont icon-laba font-16 standbyColor"></span>
          <span class="standbyColor f14 ml-6">预警公告：</span>
          <vue-seamless-scroll :data="listData" class="seamless-warp" :key="scrollKey" :class-option="defaultOption">
              <ul>
                  <li class="f14 warning_info_box" v-for="(item,i) in listData" :key="scrollKey+i">
                      <span class="sinleline">{{ item.alarmContent }}</span>
                      <span>{{ item.alarmTime }}</span>
                  </li>
              </ul>
          
          </vue-seamless-scroll>
          <el-button type="primary" size="mini" @click="toDetails" plain>详情</el-button>
      </div>
      <div :class="['details_list_box','pageBg',isOpen ? 'autoHeight': '']">
         <div class="d-flex justify-content-between align-items-center details_header_list">
           <span class="baseColor font-14">预警详情</span>
           <span class="el-icon-error baseColor cp" @click="toColse"></span>
         </div>
         <div class="details_content_box">
            <ul class="list">
                <li class="f14 pageBg align-items-center" v-for="(item,i) in listData" :key="scrollKey+i">
                   <!-- el-icon-check active -->
                    <!-- <span :class="['round','cp',checkInfo.includes(item.id) ? 'el-icon-check active' : '']" @click="toCheck(item.id)" ></span>  -->
                    <div class="d-flex flex-warp">
                      <span class="flex-warp">{{ item.alarmContent }}</span>
                      <span class="flex-shoke">{{ item.alarmTime }}</span>
                    </div>
                    
                </li>
            </ul>
          <!-- <div class="but_box mt-10 tr">
            <el-button type="primary" size="mini" @click="allCheck" plain>{{ checkButText }}</el-button>
            <el-button type="primary" size="mini" @click="toSave" plain>设置已读</el-button>
            <el-button  size="mini" @click="toColse" plain>取消</el-button>
          </div> -->
         </div>
        
      </div>
    </div>
  </template>
  
  <script>
  
   
    export default {
  
        props:{
           listData:{
               type: Array,
               default: () => []
                    
           },
          
        },
        data(){
            return {
                defaultOption: {
                
                    singleHeight: 19,
                    // waitTime: 1000,
                    // openWatch: true,
                    // hoverStop: true,
                    direction: 1,
                    limitMoveNum: 2
                
                },
                scrollKey: new Date().getTime(),
                isOpen: false,
                checkInfo: []
    
            }
        },
        mounted() {
          document.addEventListener("click", (e) => {
            let refDome = this.$refs.warnBoardRef
            if(!refDome) return
            if (!refDome ?.contains(e.target)) this.isOpen = false;
          });
        },
        computed:{
          isAllCheck(){
             return this.listData.length == this.checkInfo.length
          },
          checkButText(){ return this.isAllCheck ? '取消全选': '全选'}
        },
        methods:{
            toCheck(id){
  
              // console.log(id,this.listData)
              let i = this.checkInfo.indexOf(id)
              if(i > -1){
                this.checkInfo.splice(i,1)
              }else this.checkInfo.push(id) 
              // console.log(this.checkInfo)
            },
            async toSave(){
               if(!this.checkInfo.length) return this.$msg.error('请先选择已读预警信息')
               const res = await this.$api.LogAlarmVolumeRead({ ids: this.checkInfo})
               if(res){
                 this.$msg.success('设置成功')
                 setTimeout(()=>{
                   this.toColse()
                  
                 },500)
                 setTimeout(() => {
                     this.$emit('update')
                 },1000)
               }
            },
            updateData(){
               this.scrollKey = new Date().getTime()
  
            },
            toDetails(){
               this.isOpen= true
            },
            toColse(){
              this.isOpen= false
              this.checkInfo = []
            },
            allCheck(){
              if(this.isAllCheck) return this.checkInfo = []
              this.checkInfo = this.listData.map(item => item.id)
            }
        }
    }
  </script>
  
  <style lang="scss" scoped>
  .warn_board_box{
    //  position: relative;
    .details_list_box{
       width: 100%;
       max-height: 0px;
       overflow: hidden;
       position: absolute;
       border-radius: 6px;
       left: 0;
       top: 38px;
       z-index: 99;
       transition: 0.5s;
       height: fit-content;
       .details_header_list{
         height: 34px;
         background: #273945; 
         border-radius: 5px;
         padding: 0 15px;
         box-sizing: border-box;
       }
       .details_content_box{
          
          padding: 10px 15px;
          background: #273945; 
         border-radius: 5px;
          box-sizing: border-box;
          margin-top: 2px;
          .list{
            list-style: none;
            max-height: 240px;
            overflow-y: auto;
            li{
              display: block;
              // height: 19px;
              // line-height: 19px;
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              padding: 5px;
              border-radius: 3px;
            }
            li:not(:first-child){
              margin-top: 5px;
              
            }
          }
       }
    }
    .autoHeight{
  
      max-height: 335px;
      // transition: height 0.5s;
    }
    .round{
          display: block;
           width: 12px;
           height: 12px;
           border-radius: 10px;
           font-size: 12px;
           text-align: center;
           line-height: 12px;
           flex-shrink: 0;
           margin-right: 8px;
           border: 1px solid var(--el-color-primary);
    }
    // .activeCheck{
    //    background-color: var(--el-color-primary);
  
    // }
  }
  .board_main_box{
        display: flex;
        align-items: center;
        background: #273945;
        padding: 2px 10px;
        border-radius: 5px;
        .warning_info_box{
            display: flex;
            align-items: center;
            height: 19px;
            line-height: 19px;
            & > span:first-child{
                display: inline-block;
                max-width: 500px;
            }
            & > span:last-child{
                flex-shrink: 0;
                color: #888;
                margin: 0 10px 0 20px;
            }
        }
        .seamless-warp {
            // width: 400px;
          height: 19px;
          overflow: hidden;
          flex: 1;
        //   position: relative;
        //   overflow-y: auto;
        }
        .mr10{
            margin-right: 10px;
        }
  }
  .warp {
    height: 19px;
    width: 360px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      li,
      a {
        display: block;
        height: 19px;
        line-height: 19px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }
  }
  .flex-warp{
    flex: 1;
  }
  </style>